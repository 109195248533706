import {useTranslation} from "react-i18next";
import './Footer.css';

function Footer() {

    const {t, i18n} = useTranslation()

    return (
        <div className="container-xxl">
            <div className="row">
                <div className="col">
                    <footer>
                        <p></p>
                        <p>&copy; 2023-2025 InspectEasy | {t('footer.developedBy')} <a href="https://foilen.com/"
                                                                                       target="_blank">Foilen</a>
                            . {t('footer.allRightsReserved')}.
                        </p>
                    </footer>
                </div>
            </div>
        </div>
    )
}

export default Footer;
